$beige: #f2d6bb;
$white: #f0f3fa;
$dark-blue: #000e71;
$black: #0d1335;
$orange: #e7671c;
$dark-grey: #5d6282;
$home-blue: #F0F3F9;
$white: #FFFFFF;
$light-blue: #74B3F7;
$brown: #927b71;
$grey: #7a869a;
$light-grey: #8f99a3;
$red: #FC0505;
$green: #008000;
$grey-white: #E8E8E8;
$dark-electric-blue: #5e6282;
$cool-grey: #8891a0;
$dark-imperial-blue:#000e72;
$verdigris: #49c5b6;
$border-grey: #C7C6C6;
$space-cadet:#152e51;
$auroMetalSaurus:#6f7283;
$dark-green: #245F21;
$dark-red: #861616;
$cetacean-plue: #0c1334;
$error-red: #ff0000ff;
$ghost-white: #f5f7fb;