/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import 'bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "shared/variables/colors.scss";

body {
    background-color: $home-blue;
}

@font-face {
    font-family: 'mitr-regular';
    src: url('./assets/fonts/mitr/Mitr-Regular.ttf');
}

@font-face {
    font-family: 'mitr-medium';
    src: url('./assets/fonts/mitr/Mitr-Medium.ttf');
}

.mat-mdc-snack-bar-container.warn {
    div {
        background-color: $error-red !important;
    }
    .mdc-button__label {
        color: $white!important;
    }
}

button[disabled] {
    opacity: 50%;
    cursor: not-allowed;
}
.rounded-white-card {
    background-color: #FFFFFF;
    padding: 3.2em;
    border-radius: 0.7em;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
}

.lg-title {
    font-size: 2.5em;
}

.title-gray {
    color: #5d6282;
}

.f-lg {
    font-size: 1.2rem;
}